<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/company/xinli_adv.jpg" alt="">
    </div>
    <div class="current_menu">
      <div class="content">
        <div class="page_indicate">
          <div class="img" />
          <span>{{ menuTitle }}<span> > </span>{{ menuSubTitle }}</span>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="head">
        <p class="title">{{ info.title }}</p>
        <p class="date">发布时间：{{ info.publishDate }}</p>
      </div>
      <div class="ql-editor" v-html="info.body" />
      <div v-if="uploadList.length" class="upload_con">
        <p>附件列表</p>
        <ul>
          <li v-for="(item,index) in uploadList" :key="index">
            {{ item }}
            <a target="_blank" :href="`/website${item}`" download>预览</a>
          </li>
        </ul>
      </div>
      <p v-if="info.source" class="source">来源：{{ info.source }}</p>
      <div class="btn_box">
        <a @click="switchArchieve('pre')">上一篇：<span>{{ preArc || '无内容 ' }}</span></a>
        <a @click="switchArchieve('next')">下一篇：<span>{{ nextArc || '无内容 ' }}</span></a>
      </div>
    </div>
  </div>
</template>

<script>
import { getArchiveListByMenuId, getMainContent } from '@/plugin/api.js'

export default {
  data() {
    return {
      id: '',
      archieveIndex: -1,
      archieveList: [],
      info: {
        title: '',
        updateTime: ''
      },
      infoNum: -1,
      preArc: null,
      nextArc: null,
      uploadList: [],
      menuTitle: '',
      menuSubTitle: ''
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.init()
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    this.init()
  },
  methods: {
    init() {
      window.scrollTo(0, 0)
      const query = this.$route.query
      this.getInfo(query.menuId, query.id)
      switch (query.menuId) {
        case '136':
          this.menuTitle = '投资者关系'
          this.menuSubTitle = '公司公告'
          break
        case '137':
          this.menuTitle = '投资者关系'
          this.menuSubTitle = '公司治理'
          break
        case '145':
          this.menuTitle = '走进新力'
          this.menuSubTitle = '公司新闻'
          break
        case '154':
          this.menuTitle = '联系我们'
          this.menuSubTitle = '招聘公告'
          break
        case '157':
          this.menuTitle = '企业党建'
          this.menuSubTitle = '党建工作'
          break
      }
      this.getArchieve(query.id, '3')
    },
    switchArchieve(method) {
      let id
      switch (method) {
        case 'pre':
          if (!this.preArc) {
            return
          }
          id = this.archieveList[this.archieveIndex - 1 ].id
          break
        case 'next':
          if (!this.nextArc) {
            return
          }
          id = this.archieveList[this.archieveIndex + 1 ].id
          break
      }
      this.$router.push({ path: '/archieveDetail', query: { menuId: this.$route.query.menuId, id }})
    },
    getArchieve(url, urlType) {
      getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        this.info = res.data.data.archive
        this.uploadList = res.data.data.archive.attachmentUrl ? res.data.data.archive.attachmentUrl.split(',') : []
      })
    },
    getInfo(menuId, id) {
      getArchiveListByMenuId({ tenantId: 'xljr', menuId }).then(res => {
        this.archieveList = res.data.data.archiveList

        const num = this.archieveList.findIndex(item => {
          return `${item.id}` === id
        })
        this.archieveIndex = num
        if (this.archieveList.length) {
          this.preArc = num === 0 ? null : this.archieveList[num - 1].title
          this.nextArc = num === this.archieveList.length - 1 ? null : this.archieveList[num + 1].title
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.source{
  padding: 0 15px 12px 15px;
}
.info{
  width: 1200px;
  margin: 20px auto;
  padding: 50px 0;
  background-color: #fff;
  .head{
    text-align: center;
    margin: 0 35px 20px 35px;
    padding-bottom: 35px;
    border-bottom: 1px solid #EDEDED;
    .title{
      color: #000;
      font-size: 20px;
      margin-bottom: 15px;
    }
    .date{
      color: #999;
      font-size: 14px;
    }
  }
  ::v-deep .ql-editor{
    // background-color: #fff;
    p{
      white-space: pre-wrap;
    }
    img{
      max-width: 100%;
      display: block;
      margin: 0 auto;
    }
  }
  .word,.upload_con{
    margin: 0 35px;
    padding: 20px 0;
  }
  .btn_box{
    display: flex;
    margin: 0 35px;
    a{
      width: 500px;
      cursor: pointer;
      border-radius: 8px;
      background-color: #F1F3F9;
      padding: 0 20px;
      color: #101010;
      font-size: 16px;
      height: 60px;
      line-height: 60px;
      margin-right: 20px;
      display: flex;
      &:last-child{
        margin-right: 0;
      }
      span{
        flex: 1;
        display: inline-block;
        color: #999999;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        // width: 440px;
      }
    }

  }
}
</style>
